import PropTypes from "prop-types"
import React, { useState } from "react"
import Columns from "./columns"
import Column from "./column"
import Input from "./input"
import Stack from "./stack"
import TextLink from "./textlink"

const RhymingWords = ({ columns, rows, columnsPerRow, maxColumns }) => {
  const [currentColumns, setCurrentColumns] = useState(columns)

  const onClickMoreRows = (event) => {
    event.preventDefault()
    if (currentColumns >= maxColumns) {
      return
    }
    setCurrentColumns(currentColumns + 1)
  }

  const words = [
    "Tee",
    "verlegen",
    "Tasse",
    "Reiher",
    "fangen",
    "ergänzen",
    "Licht",
    "schäumen",
    "Unwetter",
  ]

  const renderWordColumn = ({ column }) => {
    const columnKey = `word-${column}-0`
    return (
      <Column key={columnKey}>
        <Stack space={6}>
          <Input
            id={columnKey}
            name={columnKey}
            label="Mein Wort"
            placeholder={`z.B. ${words[column]}`}
          />
          <Stack space={3}>
            {Array(rows)
              .fill()
              .map((_, i) => {
                const rowKey = `word-${column}-${i + 1}`
                return (
                  <Input
                    key={rowKey}
                    id={rowKey}
                    name={rowKey}
                    label={i === 0 && "Darauf reimt sich:"}
                  />
                )
              })}
          </Stack>
        </Stack>
      </Column>
    )
  }

  return (
    <Stack>
      {Array(currentColumns)
        .fill()
        .map((_, i) => (
          <Columns key={`column-${i}`} collapse={[true, false]}>
            {Array(columnsPerRow)
              .fill()
              .map((_, j) =>
                renderWordColumn({ column: i * columnsPerRow + j })
              )}
          </Columns>
        ))}
      {currentColumns < maxColumns && (
        <TextLink onClick={onClickMoreRows}>Ich habe noch mehr Ideen</TextLink>
      )}
    </Stack>
  )
}

RhymingWords.propTypes = {
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  columnsPerRow: PropTypes.number.isRequired,
  maxColumns: PropTypes.number.isRequired,
}

RhymingWords.defaultProps = {
  columns: 1,
  rows: 3,
  columnsPerRow: 3,
  maxColumns: 3,
}

export default RhymingWords
