import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import CourseFooterNext from "../../../../../components/course-footer-next"
import RhymingWords from "../../../../../components/rhyming-words"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "jahreszeiten",
          chapterId: "02-eigenes-gedicht",
          taskId: "reim-und-klang",
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/form-finden")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Reim & Klang" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Reim & Klang
          </Heading>
        </Stack>
        <Paragraph>
          Reime verleihen Gedichten oft eine besondere Melodie und eine
          Gleichmäßigkeit. Reime spielen mit Erwartungen, können aber auch
          unerwartete Überraschungsmomente erzeugen. Es gibt aber auch viele
          Gedichte ohne Reim. Soll dein Gedicht gereimt sein? Falls ja kannst du
          hier eine Reimsammlung anlegen.
        </Paragraph>
        <Note variant="task">
          Mache eine Liste mit Wörtern, die in deinem Gedicht vorkommen sollen
          und suche nach passenden Wörtern, die sich darauf reimen.
        </Note>
        <RhymingWords />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
